var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('img',{attrs:{"src":require("../../../assets/imgs/trial.png")}}),_c('div',{staticClass:"box"},[_c('p',{staticClass:"title"},[_c('b',[_vm._v("ORI")]),_vm._v(" "+_vm._s(_vm.$t("index.预约试驾")))]),_c('van-form',{ref:"form",staticClass:"textbox",attrs:{"label-width":"auto","colon":"","show-error":false},on:{"submit":_vm.onSubmit}},[(_vm.isCN)?_c('van-field',{staticClass:"text",attrs:{"name":"address","label":_vm.$t('index.体验地点'),"required":"","rules":[{ required: true, message: _vm.$t('index.请填写体验地点') }]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}):_c('div',{staticClass:"addressBox"},[_c('van-field',{staticClass:"text subfield",attrs:{"name":"country","label":_vm.$t('editPersonal.国家/地区'),"required":"","rules":[ { required: true, message: _vm.$t('index.请填写国家/地区') }, ]},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('van-field',{staticClass:"text subfield",attrs:{"name":"city","label":_vm.$t('index.城市'),"required":"","rules":[ { required: true, message: _vm.$t('index.请填写城市') } ]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),(_vm.isCN)?_c('div',{staticClass:"phonebox"},[(!_vm.isCN)?_c('i',{staticClass:"el-icon-plus"}):_vm._e(),(!_vm.isCN)?_c('van-field',{staticClass:"text countrycode",attrs:{"type":"tel","name":"countrycode","label":_vm.$t('register.请输入国家编码'),"required":"","rules":[
            { required: true, message: _vm.$t('register.请输入国家编码') },
          ]},model:{value:(_vm.countrycode),callback:function ($$v) {_vm.countrycode=$$v},expression:"countrycode"}}):_vm._e(),(!_vm.isCN)?_c('span',[_vm._v("  ")]):_vm._e(),_c('van-field',{staticClass:"text",attrs:{"type":"tel","name":"phone","label":_vm.$t('index.手机号'),"required":"","rules":[
            { required: true, message: _vm.$t('index.请填写手机号') },
            { message: _vm.$t('index.格式不正确'), validator: _vm.validatorTel },
          ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1):_c('div',{staticStyle:{"overflow":"hidden"}},[_c('van-field',{staticClass:"text",attrs:{"name":"email","label":_vm.$t('index.邮箱'),"required":"","rules":[
            { required: true, message: _vm.$t('index.请填写邮箱') },

          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"verifybox"},[_c('van-field',{staticClass:"verifyinput",attrs:{"name":"verify","label":_vm.$t('register.验证码'),"required":"","rules":[{ required: true, message: _vm.$t('register.请输入验证码') }]},model:{value:(_vm.verify),callback:function ($$v) {_vm.verify=$$v},expression:"verify"}}),_vm._v("   "),_c('van-button',{staticClass:"verifybtn",attrs:{"block":"","type":"info","disabled":_vm.disabled},on:{"click":_vm.onCodeBtnClick}},[_vm._v(" "+_vm._s(_vm.disabled ? `${_vm.count}s` : _vm.$t("register.获取验证码"))+" ")])],1),(!_vm.isCN)?_c('van-checkbox',{staticClass:"checkbox",attrs:{"name":"consent","label":_vm.$t('register.验证码'),"required":""},model:{value:(_vm.consent),callback:function ($$v) {_vm.consent=$$v},expression:"consent"}},[_vm._v(" I have read and agree to the "),_c('a',{attrs:{"href":"/privacy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"/terms","target":"_blank"}},[_vm._v("Terms of service.")])]):_vm._e(),_c('div',{staticClass:"submit-btn-wrap"},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit","color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("index.提交"))+" ")])],1)],1)],1),_c('ul',{staticClass:"content"},[_c('li',[_vm._v(" "+_vm._s(_vm.$t( "index.产品体验官会根据当前咨询人数情况，尽快与您致电并提供购买新能源摩托车咨询服务" ))+" ")]),(false)?_c('li',[_vm._v(_vm._s(_vm.$t("index.购车咨询点击转400")))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }